import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "introduction",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#introduction",
        "aria-label": "introduction permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Introduction`}</h1>
    <p>{`The Nettu platform lets you extend, expand, and automate your website.
Cultivate conversation, inspire action, and integrate services by building apps or workflows.`}</p>
    <p>{`The app is your sandbox, and this guide will show you some of the toys.`}</p>
    <p>{`Keep reading to get an overview of our platform's features. We'll point you in the right direction to learn even more about each piece along the way.`}</p>
    <br />
    <hr />
    <br />
    <h1 {...{
      "id": "what-can-the-nettu-platform-do",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#what-can-the-nettu-platform-do",
        "aria-label": "what can the nettu platform do permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`What can the Nettu platform do?`}</h1>
    <p>{`In short the Nettu platform will let you easily build applications that has complicated booking processes or are
doing some kind of online consulting or is a calendar applications.
Some core features of the platform is:`}</p>
    <ul>
      <li parentName="ul">{`Integrations with Google and Outlook calendars`}</li>
      <li parentName="ul">{`Conference solutions (Nettus own / Hangouts / Teams)`}</li>
      <li parentName="ul">{`Automatic payment and payout`}</li>
      <li parentName="ul">{`Own calendar solution`}</li>
      <li parentName="ul">{`Time resolution for booking processes`}</li>
      <li parentName="ul">{`Easily group and management of users and instructors`}</li>
      <li parentName="ul">{`and much more!`}</li>
    </ul>
    <h1 {...{
      "id": "get-started",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#get-started",
        "aria-label": "get started permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Get started`}</h1>
    <p>{`Get started in a few minutes by following our quickstart
`}<a parentName="p" {...{
        "href": "/getting-started"
      }}>{`Get started now!`}</a></p>
    <br />
    <hr />
    <br />
    <h1 {...{
      "id": "help-needed-",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#help-needed-",
        "aria-label": "help needed  permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Help needed ?`}</h1>
    <p>{`Dont worry! We have a dedicated technical team ready to assist you with your integration / usecase. You can
reach us anytime `}<a parentName="p" {...{
        "href": "mailto:support@nettu.no"
      }}>{`here`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      